const mockData = [
  {
    orderType: "10",
    retargetable: 0,
    orderId: "670283091620028416",
    orderStatus: "02",
    showSessions: "游戏本",
    productName:
      "24小时畅听高音质蓝牙耳机头戴式无线耳麦手机电脑通用男女生跑步运动全包跑步运动全包",
    priceList: [280],
    orderStatusName: "待付款",
    showNameLabel: "60*60cm",
    showId: 50816,
    actuallyPaidAmt: 285,
    thirdOrderId: null,
    deliveryWay: "02",
    theatreName: "北京保利票务发展有限公司",
    ticketCode: null,
    between: 875,
    createTimeFormat: "2021-11-24 15:05:42",
    orderSource: "11",
    consignee: "多鹏慧",
    productId: 33137,
    thirdOrderSource: null,
    orderTypeName: "大家都在买的热销商品",
    sheetList: ["1"],
    deliveryWayName: "快递",
    orderCode: "2111241505420172682448",
    orderFreightAmt: 5,
    projectName: "测试项目天津人艺呈现-曹禺经典话剧《雷雨》北京站",
    placeName: "北京展览馆剧场",
    projectId: "615188426222284800",
    theatreId: 40,
    projectImg:
      "https://cdn.polyt.cn/uploadImg/062eada2-f24e-4b7e-86ac-2ade30ebb8a3.jpg",
  },
];
export default mockData;
